

































































































































































































































































@import "@/assets/css/project-variables";

.mass-action-entities span.el-radio__input, .mass-action-actions span.el-radio__input {
  display: none !important;
}

.mass-action-actions{

}

.mass-action-result {
  margin-top: 20px;
}

.mass-actions-page{
  &-block{
    margin-top: 20px;

    &-name {
      font-weight: bold;
    }
  }
}

