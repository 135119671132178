










































































































@import "./src/assets/css/project-variables";

.home-navigation-container {

  a {
    text-decoration: none;
  }

  .el-card {
    .card-icon {
      padding: 0 0 0 20px;
      font-size: 30px;
      opacity: 0.5;
    }

    .card-text {
      width: 100%;
      text-align: center;
    }

    .card-text.one-line {
      padding-top: 10px;
      text-transform: uppercase;
    }
  }
}

.my-turn-counter{
  opacity: 1;
  padding-right:20px;
  color: $--color-primary;
}
