











































































































































































































































@import "./src/assets/css/project-variables";
a {
  color: $--color-primary;
}

a:hover {
  cursor: pointer;
}

.comments-block {


  &-messages {
    max-height: 50vh;
    overflow: scroll;
    overflow-x: unset;
    overflow-y: auto;
    margin-bottom: 20px;

    &-message {
      padding: 10px 20px;
      /*background-color: #f1f1f1;*/
      background-color: #F5F7FA;

      &-title {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
      }

      &-body {
        white-space: pre-wrap;
      }
    }


  }
}


.comments-block .comments-block-messages .comments-block-message:not(:last-child) {
  margin-bottom: 20px;
}


